import { memo } from "react";
import { Form, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Button, CircularProgress, Stack, useMediaQuery } from "@mui/material";

import store from "store";
import { dictionarySelectors } from "store/ducks/dictionary";
import { leadActions } from "store/ducks/lead";
import { taskThunks } from "store/ducks/task";

import { getShortName } from "utils/helpers";
import { taskSchema } from "utils/schema";
import FormikAutocomplete from "views/form/FormikAutocomplete";
import FormikDatePicker from "views/form/FormikDatePicker";
import FormikTextField from "views/form/FormikTextField";
import FormikTimePicker from "views/form/FormikTimePicker";
import InteractionActionSelector from "./InteractionActionSelector";
import { MOBILE_VIEW, TABLET_VIEW } from "utils/constants";

const ActionsNewTask = ({ selectedItem, setSelectedItem, values, isSubmitting, setIsShownMobileActionsBar }) => {
  const { t } = useTranslation();
  const employees = useSelector(dictionarySelectors.getEmployees());
  const employeesLoading = useSelector(dictionarySelectors.employeesLoading());

  const taskTypes = useSelector(dictionarySelectors.getTaskTypes());
  const taskTypesLoading = useSelector(dictionarySelectors.taskTypesLoading());
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const isTablet = useMediaQuery(TABLET_VIEW);

  return (
    <Form id="taskForm" style={{ height: "inherit", width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        backgroundColor="extra.white"
        borderRadius={1}
        p={1}
        width="100%"
      >
        <Stack>
          {!isMobile && <InteractionActionSelector setSelectedItem={setSelectedItem} selectedItem={selectedItem} />}
        </Stack>
        <Stack px={isMobile ? 0 : 1} width="100%" sx={{ ...(isMobile && { gap: 1 }) }}>
          <Stack direction="row" alignItems="center" gap={2} width="100%">
            <FormikDatePicker name="dueDate" sx={{ minWidth: 140, width: "50%", "& input": { fontSize: 12 } }} />
            <FormikTimePicker
              name="dueTime"
              sx={{ minWidth: 68, width: "50%", "& input": { fontSize: 12 } }}
              useTimezone
              date={values.dueDate}
              timeZone={values.timezone}
            />
            {!isMobile && !isTablet && (
              <Stack direction="row" alignItems="center" gap={0.5} width="100%">
                <FormikAutocomplete
                  name="type"
                  options={taskTypes || []}
                  loading={taskTypesLoading}
                  sx={{ width: "100%" }}
                  fontSize={12}
                />
              </Stack>
            )}
          </Stack>
          <FormikAutocomplete
            name="responsible"
            getCustomLabel={getShortName}
            options={employees}
            loading={employeesLoading}
            fontSize={12}
            sx={{ width: "100%" }}
            multiple
          />
          {(isTablet || isMobile) && (
            <FormikAutocomplete
              name="type"
              options={taskTypes || []}
              loading={taskTypesLoading}
              sx={{ width: "100%" }}
              fontSize={12}
            />
          )}
          {!isMobile && (
            <Stack direction="row" width="100%" gap={1} alignItems="flex-end">
              <Stack width="100%">
                <FormikTextField
                  name="name"
                  placeholder={t("base.placeholders.name")}
                  sx={{ "& input": { fontSize: 12 } }}
                />
                <FormikTextField
                  name="description"
                  placeholder={t("base.placeholders.description")}
                  sx={{ "& input": { fontSize: 12 } }}
                />
              </Stack>
              <Button
                variant="contained"
                type="submit"
                sx={{ maxWidth: "140px" }}
                disabled={isSubmitting || !values.dueDate || !values.name}
                startIcon={isSubmitting && <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} />}
              >
                {isSubmitting ? t("base.buttons.adding") : t("base.buttons.add")}
              </Button>
            </Stack>
          )}

          {isMobile && (
            <>
              <FormikTextField
                name="name"
                placeholder={t("base.placeholders.name")}
                sx={{ flex: 1, "& input": { fontSize: 12 } }}
              />
              <FormikTextField
                name="description"
                placeholder={t("base.placeholders.description")}
                sx={{ "& input": { fontSize: 12 } }}
              />
            </>
          )}
        </Stack>
      </Stack>
      {isMobile && (
        <Stack direction="row" alignItems="center" gap={2} width="100%" mt={1}>
          <Button
            variant="contained"
            type="submit"
            sx={{ width: "100%" }}
            disabled={isSubmitting || !values.dueDate || !values.name}
            startIcon={isSubmitting && <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} />}
          >
            {isSubmitting ? t("base.buttons.adding") : t("base.buttons.add")}
          </Button>
          <Button variant="outlined" sx={{ width: "100%" }} onClick={() => setIsShownMobileActionsBar(false)}>
            {t("base.buttons.cancel")}
          </Button>
        </Stack>
      )}
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ item = {}, leads, responsible }) => ({
      ...item,
      id: item?.id,
      author: item?.author,
      name: item?.name || "",
      description: item?.description || "",
      responsible: responsible,
      status: { name: "NEW", label: "New", type: "TaskStatus" },
      createdAt: item?.createdAt,
      dueDate: item?.dueDate || null,
      dueTime: item?.dueTime || null,
      files: item?.files || [],
      leads: leads,
      timezone: item?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      type: item?.type || null,
    }),
    validationSchema: taskSchema,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const response = await store.dispatch(taskThunks.createTask(values));

      if (!response.error) {
        props.setIsShownMobileActionsBar(false);
        toast.success(props.t("messages.success.toast.createTask"));
        if (response.meta.requestStatus === "fulfilled") {
          const { leads, ...rest } = response.payload;
          const updatedTasks = [...props.lead.tasks];
          updatedTasks.push(rest);

          store.dispatch(leadActions.updateItem({ collection: "lead", data: { ...props.lead, tasks: updatedTasks } }));
        }

        setSubmitting(false);
      }
    },
    enableReinitialize: true,
  })(ActionsNewTask)
);
