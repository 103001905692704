import { addMethod, object, ref, string, array, date, mixed } from "yup";
import { REG_EXP } from "./constants";
import i18next from "i18next";
import { isValidPhoneNumber } from "libphonenumber-js";
const t = i18next.t;

const getValidationMessage = (key) => {
  return t(`messages.errors.validation.${key}`);
};

/** --- Custom Yup validator for parsing the date ---  */
const parseDate = (value, originalValue) => {
  const parsedDate = new Date(originalValue);
  return isNaN(parsedDate) ? originalValue : parsedDate;
};

/* --- Strong password validation  --- */

addMethod(string, "strongPassword", strongPassword);
function strongPassword() {
  return this.test("strongPasswordTest", function (value) {
    const { path, createError } = this;

    if (value === null || value === "" || value === undefined) {
      return true;
    }

    switch (Boolean(value)) {
      case !REG_EXP.lower.test(value):
        return createError({
          path,
          message: getValidationMessage("lowerCase"),
        });
      case !REG_EXP.upper.test(value):
        return createError({
          path,
          message: getValidationMessage("upperCase"),
        });
      case !REG_EXP.digit.test(value):
        return createError({ path, message: getValidationMessage("digit") });
      case !REG_EXP.specChar.test(value):
        return createError({ path, message: getValidationMessage("specChar") });
      default:
        return true;
    }
  });
}

/* --- Phone validation  --- */

addMethod(string, "phone", phone);

function phone() {
  return this.test("phoneTest", function (value) {
    const { path, createError } = this;
    const isValid = value && isValidPhoneNumber(value);

    switch (Boolean(value)) {
      case !isValid:
        return createError({
          path,
          message: () => getValidationMessage("phone"),
        });

      default:
        return true;
    }
  });
}

/* --- LOGIN FORM --- */

export const logInFormSchema = object({
  email: string(),
  password: string().required(() => getValidationMessage("required")),
});

/* --- FORGOT PASSWORD FORM --- */

export const forgotPasswordSchema = object({
  email: string()
    .email(() => getValidationMessage("email"))
    .required(() => getValidationMessage("required")),
});

/* --- RESET PASSWORD FORM --- */

export const resetPasswordSchema = object({
  token: string().required(() => getValidationMessage("required")),
  password: string()
    .required(() => getValidationMessage("required"))
    .strongPassword()
    .min(8, () => getValidationMessage("passwordMinLength")),
  confirmPassword: string()
    .required(() => getValidationMessage("required"))
    .oneOf([ref("password")], () => getValidationMessage("confirmPassword")),
});

/* --- DICTIONARY FORM --- */

export const dictionaryItemSchema = object({
  name: string().required(() => getValidationMessage("required")),
});

/* --- CHANGE PASSWORD FORM --- */

export const changePasswordFormSchema = object({
  newPassword: string()
    .required(() => getValidationMessage("required"))
    .strongPassword()
    .min(8, () => getValidationMessage("passwordMinLength")),

  confirmPassword: string()
    .required(() => getValidationMessage("required"))
    .oneOf([ref("password")], () => getValidationMessage("confirmPassword")),
});

/* --- PIPELINE FORM --- */

export const pipelineFormSchema = object({
  name: string().required(() => getValidationMessage("required")),
});

/* --- CLOSE LEAD FORM --- */

export const closeLeadSchema = object({
  closeReason: object()
    .nullable()
    .required(() => getValidationMessage("required")),
});

/* --- NEW LEAD FORM --- */

export const newLeadSchema = object({
  name: string().required(() => getValidationMessage("required")),
  phones: array()
    .of(
      string()
        .phone()
        .required(() => getValidationMessage("required"))
    )
    .min(1, () => getValidationMessage("required")),
  emails: array().of(string().email(() => getValidationMessage("email"))),
  leadName: string().required(() => getValidationMessage("required")),
  responsible: object()
    .nullable()
    .required(() => getValidationMessage("required")),
  pipeline: object()
    .nullable()
    .required(() => getValidationMessage("required")),
  stage: object()
    .nullable()
    .required(() => getValidationMessage("required")),
});

/* --- CURRENT LEAD, CONTACT FORM --- */

export const contactSchema = object({
  name: string().required(() => getValidationMessage("required")),
  messenger: string(),
  phones: array()
    .of(
      string()
        .phone()
        .required(() => getValidationMessage("required"))
    )
    .min(1, () => getValidationMessage("required")),
  emails: array().of(string().email(() => getValidationMessage("email"))),
});

/* --- CURRENT LEAD, LEAD DETAILS FORM  --- */

export const leadDetailsSchema = object({
  name: string().required(() => getValidationMessage("required")),
  responsible: object()
    .nullable()
    .required(() => getValidationMessage("required")),
  pipeline: object()
    .nullable()
    .required(() => getValidationMessage("required")),
  stage: object()
    .nullable()
    .required(() => getValidationMessage("required")),
  status: object()
    .nullable()
    .required(() => getValidationMessage("required")),
});

/* --- TASK FORM ---- */

export const taskSchema = object({
  name: string().required(() => getValidationMessage("required")),
  description: string(),
  responsible: array()
    .min(1, () => getValidationMessage("required"))
    .of(object())
    .required(() => getValidationMessage("required")),
  status: object()
    .nullable()
    .required(() => getValidationMessage("required")),
  date: date().nullable(),
  dueDate: mixed()
    .test({
      name: "parseDate",
      message: getValidationMessage("invalidDate"),
      exclusive: true,
      test: (value) => {
        return !isNaN(new Date(value));
      },
      parse: (value) => parseDate(value, value),
    })
    .nullable(),
  leads: array().of(object().nullable()),
});
